import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, FormFeedback, Modal, Form, } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const ServicesDashboard = () => {
    const BASE_URL = 'https://codingfectum.com';
    const [modal_standard, setmodal_standard] = useState(false);
    const [selected, setSelected] = useState(null);

    const [services, setServices] = useState([]);
    const [service, setService] = useState({
        id: '',
        name: '',
        excerpt: '',
        svg: '',
        datawowdelay: '',
        detailedDescription: {
            description: ['', ''],
            features: [{ SingleFeature: '' }],
            additionalImageRight: '',
            additionalImageLeft: '',
            faqs: [{ question: '', answer: '' }]
        }
    });
    const [isEditing, setIsEditing] = useState(false);


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstname: "",
            lastname: "",
            city: "",
            state: "",
            zip: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please Enter Your First Name"),
            lastname: Yup.string().required("Please Enter Your Last Name"),
            city: Yup.string().required("Please Enter Your City"),
            state: Yup.string().required("Please Enter Your State"),
            zip: Yup.string().required("Please Enter Your Zip"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
        },
    });
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: "",
            password: "",
            password1: "",
            email: "",
            digits: "",
            number: "",
            alphanumeric: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required("This value is required"),
            password: Yup.string().required("This value is required"),
            password1: Yup.string().when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                ),
            }),
            email: Yup.string()
                .email("Must be a valid Email")
                .max(255)
                .required("Email is required"),
            url: Yup.string()
                .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                )
                .required("Please enter correct Url"),
            digits: Yup.number().required("Please Enter Your Digits"),
            number: Yup.number().required("Please Enter Your Number"),
            alphanumeric: Yup.string()
                .matches(/^[a-z0-9]+$/i, "Enter correct Alphanumeric!")
                .required("Please Enter Your Alphanumeric"),
            textarea: Yup.string().required("Please Enter Your Textarea"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
        },
    });
    const handleChangeInService = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');
        if (keys?.length === 1) {
            setService((prevService) => ({
                ...prevService,
                [name]: value
            }));
        } else {
            setService((prevService) => ({
                ...prevService,
                [keys[0]]: {
                    ...prevService[keys[0]],
                    [keys[1]]: value
                }
            }));
        }
    };

    const handleParagraphChange = (e, index) => {
        const { name, value } = e.target;
        const updatedParagraphs = [...service?.detailedDescription?.description];
        updatedParagraphs[index] = value;
        setService((prevService) => ({
            ...prevService,
            detailedDescription: {
                ...prevService?.detailedDescription,
                description: updatedParagraphs
            }
        }));
    };

    const handleFeatureChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFeatures = [...service?.detailedDescription?.features];
        updatedFeatures[index] = { SingleFeature: value };
        setService((prevService) => ({
            ...prevService,
            detailedDescription: {
                ...prevService?.detailedDescription,
                features: updatedFeatures
            }
        }));
    };
    const addFeatureField = () => {
        setService(prevService => ({
            ...prevService,
            detailedDescription: {
                ...prevService.detailedDescription,
                features: [...prevService.detailedDescription.features, { SingleFeature: '' }]
            }
        }));
    };

    const removeFeatureField = (index) => {
        setService(prevService => {
            const updatedFeatures = prevService.detailedDescription.features.filter((_, i) => i !== index);
            return {
                ...prevService,
                detailedDescription: {
                    ...prevService.detailedDescription,
                    features: updatedFeatures
                }
            };
        });
    };

    const handleFaqChange = (e, index, field) => {
        const { name, value } = e.target;
        const updatedFaqs = [...service?.detailedDescription?.faqs];
        updatedFaqs[index] = { ...updatedFaqs[index], [field]: value };
        setService((prevService) => ({
            ...prevService,
            detailedDescription: {
                ...prevService?.detailedDescription,
                faqs: updatedFaqs
            }
        }));
    };
    const addFaqField = () => {
        setService(prevService => ({
            ...prevService,
            detailedDescription: {
                ...prevService.detailedDescription,
                faqs: [...prevService.detailedDescription.faqs, { question: '', answer: '' }]
            }
        }));
    };

    const removeFaqField = (index) => {
        setService(prevService => {
            const updatedFaqs = prevService.detailedDescription.faqs.filter((_, i) => i !== index);
            return {
                ...prevService,
                detailedDescription: {
                    ...prevService.detailedDescription,
                    faqs: updatedFaqs
                }
            };
        });
    };


    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleDeleteClick = (service) => {
        setSelected(service);
        tog_standard();
    };
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/services`);
                setServices(response);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchServices();
    }, [services]);
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         if (isEditing) {
    //             const response = await fetch(`${BASE_URL}/api/services`, {
    //                 method: 'PUT',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(service),
    //             });
    //             if (response.ok) {
    //                 const data = await response.json();
    //                 console.log(data.message);
    //             } else {
    //                 const errorData = await response.json();
    //                 console.error('Error:', errorData.message);
    //             }
    //         } else {
    //             await axios.post(`${BASE_URL}/api/services`, service, {
    //                 headers: { 'Content-Type': 'application/json' },
    //             });
    //         }
    //         setService({
    //             id: '',
    //             name: '',
    //             excerpt: '',
    //             svg: '',
    //             datawowdelay: '',
    //             detailedDescription: {
    //                 description: ['', ''],
    //                 features: [{ SingleFeature: '' }],
    //                 additionalImageRight: '',
    //                 additionalImageLeft: '',
    //                 faqs: [{ question: '', answer: '' }],
    //             }
    //         });
    //         setIsEditing(false);
    //         const servicesResponse = await axios.get(`${BASE_URL}/api/services`);
    //         console.log("servicesResponse", servicesResponse);
    //         console.log("servicesResponse.data", servicesResponse.data);
    //         setServices(servicesResponse.data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response, data;
            if (isEditing) {
                response = await fetch(`${BASE_URL}/api/services`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(service),
                });
                if (response.ok) {
                    data = await response.json();
                    console.log(data.message);
                    setServices((prevServices) => prevServices.map((b) => (b.id === service.id ? service : b)));
                } else {
                    const errorData = await response.json();
                    console.error('Error:', errorData.message);
                    return;
                }
            } else {
                response = await axios.post(`${BASE_URL}/api/services`, service, {
                    headers: { 'Content-Type': 'application/json' }
                });
                data = response.data;
                setServices((prevServices) => [...prevServices, data]);
            }
            setService({
                id: '',
                name: '',
                excerpt: '',
                svg: '',
                datawowdelay: '',
                detailedDescription: {
                    description: ['', ''],
                    features: [{ SingleFeature: '' }],
                    additionalImageRight: '',
                    additionalImageLeft: '',
                    faqs: [{ question: '', answer: '' }],
                }
            });
            setIsEditing(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/api/services`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id }),
            });
            if (response.ok) {
                const data = await response.json();
                console.log('data', data);
                console.log(data.message);
                // setmodal_standard(!modal_standard);
                setServices(services.filter((service) => service._id !== id));
                setmodal_standard(false);
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData.message);
            }
        } catch (error) {
            console.error('Network error Delete:', error);
        }
    };

    const handleEdit = (service) => {
        setService(service);
        setIsEditing(true);
    };


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Services Editor</h4>
                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <div className="mb-3">
                                                        <Label className="form-label">ID</Label>
                                                        <Input
                                                            name="id" value={service?.id} onChange={handleChangeInService}
                                                            placeholder="Enter ID"
                                                            type="number"
                                                            // onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            // value={validationType.values.number || ""}
                                                            invalid={
                                                                validationType.touched.number &&
                                                                    validationType.errors.number
                                                                    ? true
                                                                    : false
                                                            }
                                                            required
                                                        />
                                                        {validationType.touched.number &&
                                                            validationType.errors.number ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.number}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">Service Name</Label>
                                                    <Input
                                                        name="name" value={service?.name} onChange={handleChangeInService} required
                                                        // name="lastname"
                                                        placeholder="Service Name"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom02"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.lastname || ""}
                                                        invalid={
                                                            validation.touched.lastname &&
                                                                validation.errors.lastname
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.lastname &&
                                                        validation.errors.lastname ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.lastname}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Excerpt</Label>

                                                    <Input
                                                        name="excerpt" value={service?.excerpt} onChange={handleChangeInService} required
                                                        type="textarea"
                                                        id="textarea"
                                                        rows="3"
                                                        placeholder="This textarea has a limit of 225 chars."
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">SVG</Label>
                                                    <Input
                                                        type="text" name="svg" value={service?.svg} onChange={handleChangeInService} required
                                                        // name="city"
                                                        placeholder="SVG Code"
                                                        // type="text"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Description</Label>

                                                    <Input name="detailedDescription.description" value={service?.detailedDescription?.description[0]} onChange={(e) => handleParagraphChange(e, 0)} required
                                                        type="textarea"
                                                        id="textarea"
                                                        rows="3"
                                                        placeholder="First Paragraph."
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Description</Label>

                                                    <Input name="detailedDescription.description" value={service?.detailedDescription?.description[1]} onChange={(e) => handleParagraphChange(e, 1)} required
                                                        type="textarea"
                                                        id="textarea"
                                                        rows="3"
                                                        placeholder="Second Paragraph."
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Image Link</Label>
                                                    <Input type="text" name="detailedDescription.additionalImageRight" value={service?.detailedDescription?.additionalImageRight} onChange={handleChangeInService} required

                                                        placeholder="First Image"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Image Link</Label>
                                                    <Input type="text" name="detailedDescription.additionalImageLeft" value={service?.detailedDescription?.additionalImageLeft} onChange={handleChangeInService} required

                                                        placeholder="First Image"
                                                        className="form-control"
                                                        id="validationCustom04"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.state &&
                                                                validation.errors.state
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.state &&
                                                        validation.errors.state ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.state}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Delay Animation</Label>
                                                    <Input
                                                        type="text" name="datawowdelay" value={service?.datawowdelay} onChange={handleChangeInService} required
                                                        placeholder="Delay Animation //e.g.200ms"
                                                        className="form-control"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    {service?.detailedDescription?.features.map((feature, index) => (
                                                        <div key={index}>
                                                            <Label htmlFor="validationCustom05">{index + 1}. Feature </Label>
                                                            <div key={index} style={{ display: "flex" }}>
                                                                <Input
                                                                    className="form-control"
                                                                    id="validationCustom05"
                                                                    value={feature?.SingleFeature}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={e => handleFeatureChange(e, index)}
                                                                    required
                                                                    invalid={
                                                                        validation.touched.zip && validation.errors.zip
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.zip && validation.errors.zip ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.zip}
                                                                    </FormFeedback>
                                                                ) : null}

                                                                <Button style={{ margin: "0 0 0 10px" }} color="primary" type="button" onClick={() => removeFeatureField(index)}>Remove</Button>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <Button className="mt-2" color="primary" type="button" onClick={addFeatureField}>Add Feature</Button>

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {service?.detailedDescription?.faqs.map((faq, index) => (
                                            <div key={index}>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom05"> {index + 1}. FAQ Question</Label>
                                                            <div key={index} style={{ display: "flex" }}>
                                                                <Input
                                                                    className="form-control"
                                                                    id="validationCustom05"
                                                                    onBlur={validation.handleBlur}
                                                                    value={faq?.question}
                                                                    onChange={e => handleFaqChange(e, index, 'question')}
                                                                    required
                                                                    invalid={
                                                                        validation.touched.zip && validation.errors.zip
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.zip && validation.errors.zip ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.zip}
                                                                    </FormFeedback>
                                                                ) : null}

                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="validationCustom06">{index + 1}. FAQ Answer</Label>
                                                            <div key={index} style={{ display: "flex" }}>
                                                                <Input
                                                                    className="form-control"
                                                                    id="validationCustom06"
                                                                    onBlur={validation.handleBlur}
                                                                    value={faq?.answer}
                                                                    onChange={e => handleFaqChange(e, index, 'answer')}
                                                                    required
                                                                    invalid={
                                                                        validation.touched.zip && validation.errors.zip
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {validation.touched.zip && validation.errors.zip ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.zip}
                                                                    </FormFeedback>
                                                                ) : null}

                                                                <Button style={{ margin: "0 0 0 10px" }} color="primary" type="button" onClick={() => removeFaqField(index)}>Remove</Button>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Col md="6">
                                            <Button className="mt-1" color="primary" type="button" onClick={addFaqField}>Add FAQ</Button>
                                        </Col>

                                        <Button className='mt-3' color="primary" type="submit">{isEditing ? 'Update Service' : 'Add Service'}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Services Panel</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" >ID</th>
                                            <th scope="col" > Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {services?.map((service) => (<tr key={service?.id}>
                                            <td>
                                                <p className="mb-1 font-size-12">{service?.id}</p>
                                            </td>
                                            <td>
                                                <h5 className="font-size-15 mb-0">{service?.name}</h5>
                                            </td>

                                            <td>
                                                <button type="button" onClick={() => handleEdit(service)} className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" onClick={() => handleDeleteClick(service)} data-toggle="modal" data-target="#myModal" className="btn btn-outline-danger btn-sm me-1">Delete</button>

                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <tr>
                <td> */}

            {/* </td>
            </tr> */}
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        Delete Service
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h5>
                        This Service <b>{selected?.id}</b> will be deleted permanently
                    </h5>
                    {/* <p></p> */}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(selected?.id)}>
                        Delete
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ServicesDashboard;