// import React from 'react';
import axios from 'axios';
// import { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, FormFeedback, Modal, Form, } from "reactstrap";

import React, { useState, useEffect } from 'react';
// import dynamic from 'next/dynamic';
import { v4 as uuidv4 } from 'uuid';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai.css';
import ReactQuill,{Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import Quill from 'quill';

const Blog = () => {
    const BASE_URL = 'https://codingfectum.com';
    const [modal_standard, setmodal_standard] = useState(false);
    const [selected, setSelected] = useState(null);

    const [blogs, setBlogs] = useState([]);
    const [id, setId] = useState('');
    const [metatitle, setmetatitle] = useState('');
    const [metadescription, setmetadescription] = useState('');
    const [metaurl, setmetaurl] = useState('');
    const [metakeywords, setmetakeywords] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [titleimg, setTitleimg] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState('');
    const [blogby, setBlogby] = useState('');
    const [bloggerlink, setBloggerlink] = useState('');
    const [editingBlog, setEditingBlog] = useState(null);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {

            const response = await fetch(`${BASE_URL}/api/articles`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setBlogs(data);
            } else {
                setBlogs([]);
                console.error("Unexpected API response:", data);
            }
        } catch (error) {
            console.error("Error fetching blogs:", error);
            setBlogs([]);
        }
    };

    const handleCreateOrUpdate = async () => {
        const blogData = {
            metatitle,
            metadescription,
            metaurl,
            id,
            slug,
            title,
            content,
            titleimg,
            category,
            metakeywords: metakeywords.split(',').map(tag => ({ SingleKeyword: tag.trim() })), // Convert tags string to array
            tags: tags.split(',').map(tag => ({ SingleTag: tag.trim() })), // Convert tags string to array
            blogby,
            bloggerlink
        };


        try {
            let response;
            if (editingBlog) {

                response = await fetch(`${BASE_URL}/api/articles/update`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(blogData),
                });
            } else {
                console.log("BEFORE id", id);
                if (!id) {
                    console.log("AFTER id", id);

                    // Generate a new UUID if id is not provided
                    setId(uuidv4());
                }

                response = await fetch(`${BASE_URL}/api/articles/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...blogData, id }),
                });
            }

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Failed response:", response, errorData);
                throw new Error(errorData.error || 'Failed to create or update blog');
            }

            const responseData = await response.json();

            resetForm();
            fetchBlogs();
        } catch (error) {
            alert(`Error: ${error.message}`);
            console.error("Error creating or updating blog:", error.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/api/articles/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id }),
            });

            if (!response.ok) {
                const errorData = await response.json();

                throw new Error(errorData.error || 'Failed to delete blog');
            }
            setmodal_standard(false);
            fetchBlogs();
        } catch (error) {
            console.error("Error deleting blog:", error);
        }
    };

    const resetForm = () => {
        setId('');
        setmetatitle('');
        setmetadescription('');
        setmetaurl('');
        setmetakeywords('');
        setTitle('');
        setSlug('');
        setContent('');
        setTitleimg('');
        setCategory('');
        setTags('');
        setBlogby('');
        setBloggerlink('');
        setEditingBlog(null);
    };

    const handleEdit = (blog) => {
        setEditingBlog(blog);
        setId(blog.id);
        setmetatitle(blog.metatitle);
        setmetadescription(blog.metadescription);
        setmetaurl(blog.metaurl);
        setmetakeywords(blog.metakeywords.map(keyword => keyword.SingleKeyword).join(', ')); // Convert tags array to string
        setTitle(blog.title);
        setSlug(blog.slug);
        setContent(blog.content);
        setTitleimg(blog.titleimg);
        setCategory(blog.category);
        setTags(blog.tags.map(tag => tag.SingleTag).join(', ')); // Convert tags array to string
        setBlogby(blog.blogby);
        setBloggerlink(blog.bloggerlink);
    };
   
// Register custom font sizes
const Size = Quill.import('formats/size');
Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '40px', '48px', '56px', '64px', '72px', '96px'];
Quill.register(Size, true);

const modules = {
    toolbar: [
        [{ 'font': [] }, { 'size': Size.whitelist }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const formats = [
    'font', 'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
    'script', 'header', 'list', 'bullet', 'indent', 'direction', 'align', 'blockquote',
    'code-block', 'link', 'image', 'video'
];

    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleDeleteClick = (blog) => {
        setSelected(blog);
        tog_standard();
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Blog Editor</h4>
                                    <Form className="needs-validation" >
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <div className="mb-3">
                                                        <Label className="form-label">ID</Label>
                                                        <Input
                                                            type="number"
                                                            placeholder="id"
                                                            value={id}
                                                            onChange={(e) => setId(e.target.value)}
                                                            required
                                                        />
                                                    </div>

                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">Blog Title</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Title"
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Meta Title</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="metatitle"
                                                        value={metatitle}
                                                        onChange={(e) => setmetatitle(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Meta Description</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="metadescription"
                                                        value={metadescription}
                                                        onChange={(e) => setmetadescription(e.target.value)}

                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Meta Url</Label>
                                                    <Input type="text"
                                                        placeholder="metaurl"
                                                        value={metaurl}
                                                        onChange={(e) => setmetaurl(e.target.value)}

                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Meta Keywords</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Meta Keywords (comma-separated) "
                                                        value={metakeywords}
                                                        onChange={(e) => setmetakeywords(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Slug</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Slug"
                                                        value={slug}
                                                        onChange={(e) => setSlug(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Title Image URL</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Title Image URL"
                                                        value={titleimg}
                                                        onChange={(e) => setTitleimg(e.target.value)}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Category</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Category"
                                                        value={category}
                                                        onChange={(e) => setCategory(e.target.value)}

                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Tags</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Tags (comma-separated)"
                                                        value={tags}
                                                        onChange={(e) => setTags(e.target.value)}

                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Blog By</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Blog By"
                                                        value={blogby}
                                                        onChange={(e) => setBlogby(e.target.value)}

                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Blogger Link</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Blogger Link"
                                                        value={bloggerlink}
                                                        onChange={(e) => setBloggerlink(e.target.value)}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <ReactQuill
            style={{ height: "300px" }}
            value={content}
            onChange={setContent}
            theme="snow"
            modules={modules}
            formats={formats}
        />
                                        <br />
                                        <br />
                                        <br />
                                        <br />

                                        <Button className='mt-6' onClick={handleCreateOrUpdate} color="primary" >{editingBlog ? 'Update Blog' : 'Add Blog'}
                                        </Button>
                                        &nbsp;
                                        &nbsp;
                                        <Button className='mt-6' onClick={resetForm} color="primary" >Cancel
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Existing Blogs</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" >ID</th>
                                            <th scope="col" > Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blogs?.map((blog) => (<tr key={blog?.id}>
                                            <td>
                                                <p className="mb-1 font-size-12">{blog?.id}</p>
                                            </td>
                                            <td>
                                                <h5 className="font-size-15 mb-0">{blog?.title}</h5>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    onClick={() => handleEdit(blog)}
                                                    className="btn btn-outline-success btn-sm me-1"
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteClick(blog)}
                                                    className="btn btn-outline-danger btn-sm me-1"
                                                >
                                                    Delete
                                                </button>   </td>

                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Delete Blog
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h5>
                        This Blog <b>{selected?.id}</b> will be deleted permanently
                    </h5>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(selected?.id)}
                    >
                        Delete
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Blog;