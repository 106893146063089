import React from "react";

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Portfolio from "./Portfolio";

const PortfolioDashboard = () => {
  document.title = "Dashboard | Upzet - React Admin & PortfolioDashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Upzet" breadcrumbItem="Portfolio Dashboard" />
          <Portfolio />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PortfolioDashboard;
