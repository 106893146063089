import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, FormFeedback, Modal, Form, } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const Portfolio = () => {
    const BASE_URL = 'https://codingfectum.com';
    const [modal_standard, setmodal_standard] = useState(false);
    const [selected, setSelected] = useState(null);

    const [portfolios, setPortfolios] = useState([]);
    const [portfolio, setPortfolio] = useState({
        id: '',
        title: '',
        client: '',
        titleimg: '',
        detailedDescription: {
            coverimg: '',
            description: ['', '', ''],
            detailedimgfirst: '',
            detailedimgsecond: '',
            features: [{ SingleFeature: '' }],
            category: '',
            industry: '',
            stack: '',
        }
    });
    const [isEditing, setIsEditing] = useState(false);
    console.log("portfolios", portfolios)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstname: "",
            lastname: "",
            city: "",
            state: "",
            zip: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please Enter Your First Name"),
            lastname: Yup.string().required("Please Enter Your Last Name"),
            city: Yup.string().required("Please Enter Your City"),
            state: Yup.string().required("Please Enter Your State"),
            zip: Yup.string().required("Please Enter Your Zip"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
        },
    });
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: "",
            password: "",
            password1: "",
            email: "",
            digits: "",
            number: "",
            alphanumeric: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required("This value is required"),
            password: Yup.string().required("This value is required"),
            password1: Yup.string().when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                ),
            }),
            email: Yup.string()
                .email("Must be a valid Email")
                .max(255)
                .required("Email is required"),
            url: Yup.string()
                .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    "Enter correct url!"
                )
                .required("Please enter correct Url"),
            digits: Yup.number().required("Please Enter Your Digits"),
            number: Yup.number().required("Please Enter Your Number"),
            alphanumeric: Yup.string()
                .matches(/^[a-z0-9]+$/i, "Enter correct Alphanumeric!")
                .required("Please Enter Your Alphanumeric"),
            textarea: Yup.string().required("Please Enter Your Textarea"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
        },
    });
    const handleChangeInPortfolio = (e) => {
        const { name, value } = e.target;
        const keys = name.split('.');
        if (keys.length === 1) {
            setPortfolio((prevPortfolio) => ({
                ...prevPortfolio,
                [name]: value
            }));
        } else {
            setPortfolio((prevPortfolio) => ({
                ...prevPortfolio,
                [keys[0]]: {
                    ...prevPortfolio[keys[0]],
                    [keys[1]]: value
                }
            }));
        }
    };

    const handleParagraphChange = (e, index) => {
        const { value } = e.target;
        const updatedParagraphs = [...portfolio?.detailedDescription?.description];
        updatedParagraphs[index] = value;
        setPortfolio((prevPortfolio) => ({
            ...prevPortfolio,
            detailedDescription: {
                ...prevPortfolio?.detailedDescription,
                description: updatedParagraphs
            }
        }));
    };

    const handleFeatureChange = (e, index) => {
        const { value } = e.target;
        const updatedFeatures = [...portfolio?.detailedDescription?.features];
        updatedFeatures[index] = { SingleFeature: value };
        setPortfolio((prevPortfolio) => ({
            ...prevPortfolio,
            detailedDescription: {
                ...prevPortfolio?.detailedDescription,
                features: updatedFeatures
            }
        }));
    };

    const addFeatureField = () => {
        setPortfolio(prevPortfolio => ({
            ...prevPortfolio,
            detailedDescription: {
                ...prevPortfolio.detailedDescription,
                features: [...prevPortfolio.detailedDescription.features, { SingleFeature: '' }]
            }
        }));
    };

    const removeFeatureField = (index) => {
        setPortfolio(prevPortfolio => {
            const updatedFeatures = prevPortfolio.detailedDescription.features.filter((_, i) => i !== index);
            return {
                ...prevPortfolio,
                detailedDescription: {
                    ...prevPortfolio.detailedDescription,
                    features: updatedFeatures
                }
            };
        });
    };


    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleDeleteClick = (portfolio) => {
        setSelected(portfolio);
        tog_standard();
    };
    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/portfolio`);
                setPortfolios(response);
            } catch (error) {
                console.error('Error fetching portfolio items:', error);
            }
        };

        fetchPortfolios();
    }, [portfolios]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response, data;
            if (isEditing) {
                response = await fetch(`${BASE_URL}/api/portfolio`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(portfolio),
                });
                if (response.ok) {
                    data = await response.json();
                    console.log(data.message);
                    setPortfolios((prevPortfolios) => prevPortfolios.map((b) => (b.id === portfolio.id ? portfolio : b)));
                } else {
                    const errorData = await response.json();
                    console.error('Error:', errorData.message);
                    return;
                }
            } else {
                response = await axios.post(`${BASE_URL}/api/portfolio`, portfolio, {
                    headers: { 'Content-Type': 'application/json' }
                });
                data = response.data;
                setPortfolios((prevPortfolios) => [...prevPortfolios, data]);
            }
            setPortfolio({
                id: '',
                title: '',
                client: '',
                titleimg: '',
                detailedDescription: {
                    coverimg: '',
                    description: ['', '', ''],
                    detailedimgfirst: '',
                    detailedimgsecond: '',
                    features: [{ SingleFeature: '' }],
                    category: '',
                    industry: '',
                    stack: '',
                }
            });
            setIsEditing(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${BASE_URL}/api/portfolio`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            })
                .then(async (response) => {
                    if (response.ok) {
                        const data = await response.json();
                        console.log(data.message);
                        setPortfolios(portfolios.filter(portfolio => portfolio.id !== id));
                        setmodal_standard(false);
                    } else {
                        const errorData = await response.json();
                        console.error('Error:', errorData.message);
                    }
                })
                .catch((error) => {
                    console.error('Network error:', error);
                });
        } catch (error) {
            console.error(error);
        }
    };


    const handleEdit = (portfolio) => {
        setPortfolio(portfolio);
        setIsEditing(true);
    };
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Portfolio Editor</h4>
                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <div className="mb-3">
                                                        <Label className="form-label">ID</Label>
                                                        <Input
                                                            name="id" value={portfolio?.id} onChange={handleChangeInPortfolio}
                                                            placeholder="Enter ID"
                                                            type="number"
                                                            // onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            // value={validationType.values.number || ""}
                                                            invalid={
                                                                validationType.touched.number &&
                                                                    validationType.errors.number
                                                                    ? true
                                                                    : false
                                                            }
                                                            required
                                                        />
                                                        {validationType.touched.number &&
                                                            validationType.errors.number ? (
                                                            <FormFeedback type="invalid">
                                                                {validationType.errors.number}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">Portfolio Title</Label>
                                                    <Input
                                                        name="title" value={portfolio?.title} onChange={handleChangeInPortfolio} required
                                                        // name="lastname"
                                                        placeholder="Portfolio Title"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom02"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.lastname || ""}
                                                        invalid={
                                                            validation.touched.lastname &&
                                                                validation.errors.lastname
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.lastname &&
                                                        validation.errors.lastname ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.lastname}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Client</Label>

                                                    <Input
                                                        name="client" value={portfolio?.client} onChange={handleChangeInPortfolio} required
                                                        type="text"
                                                        rows="3"
                                                        placeholder="Client."
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Title Image</Label>
                                                    <Input
                                                        type="text" name="titleimg" value={portfolio?.titleimg} onChange={handleChangeInPortfolio} required
                                                        // name="city"
                                                        placeholder="TitleImage"
                                                        // type="text"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Cover Image</Label>
                                                    <Input
                                                        type="text" name="detailedDescription.coverimg" value={portfolio?.detailedDescription?.coverimg} onChange={handleChangeInPortfolio} required
                                                        // name="city"
                                                        placeholder="TitleImage"
                                                        // type="text"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {portfolio?.detailedDescription?.description.map((paragraph, index) => (
                                                <Col md="6">

                                                    <FormGroup className="mb-3" key={index}>
                                                        <Label htmlFor="validationCustom01">Description {index + 1}</Label>
                                                        <Input
                                                            name="detailedDescription.description"
                                                            type="textarea"
                                                            id="textarea"
                                                            rows="3"
                                                            placeholder={index + 1 + "st Paragraph"}
                                                            value={paragraph}
                                                            onChange={e => handleParagraphChange(e, index)}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            ))}
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup className="mb-3">
                                                        {portfolio?.detailedDescription?.features.map((feature, index) => (
                                                            <div key={index}>
                                                                <Label htmlFor="validationCustom05">{index + 1}. Feature </Label>
                                                                <div key={index} style={{ display: "flex" }}>
                                                                    <Input
                                                                        className="form-control"
                                                                        id="validationCustom05"
                                                                        value={feature?.SingleFeature}
                                                                        onBlur={validation.handleBlur}
                                                                        onChange={e => handleFeatureChange(e, index)}
                                                                        required
                                                                        invalid={
                                                                            validation.touched.zip && validation.errors.zip
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.zip && validation.errors.zip ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.zip}
                                                                        </FormFeedback>
                                                                    ) : null}

                                                                    <Button style={{ margin: "0 0 0 10px" }} color="primary" type="button" onClick={() => removeFeatureField(index)}>Remove</Button>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <Button className="mt-2" color="primary" type="button" onClick={addFeatureField}>Add Feature</Button>

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Image First</Label>
                                                    <Input type="text" name="detailedDescription.detailedimgfirst" value={portfolio?.detailedDescription?.detailedimgfirst} onChange={handleChangeInPortfolio}

                                                        placeholder="First Image"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        // value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Second Image</Label>
                                                    <Input type="text" name="detailedDescription.detailedimgsecond" value={portfolio?.detailedDescription?.detailedimgsecond} onChange={handleChangeInPortfolio}

                                                        placeholder="Second Image"
                                                        className="form-control"
                                                        id="validationCustom04"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.state &&
                                                                validation.errors.state
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.state &&
                                                        validation.errors.state ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.state}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Category</Label>
                                                    <Input
                                                        type="text" name="detailedDescription.category" value={portfolio?.detailedDescription?.category} onChange={handleChangeInPortfolio} required
                                                        placeholder="category"
                                                        className="form-control"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Stack</Label>
                                                    <Input
                                                        type="text" name="detailedDescription.stack" value={portfolio?.detailedDescription?.stack} onChange={handleChangeInPortfolio} required
                                                        placeholder="stack"
                                                        className="form-control"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom03">Industry</Label>
                                                    <Input
                                                        type="text" name="detailedDescription.industry" value={portfolio?.detailedDescription?.industry} onChange={handleChangeInPortfolio} required
                                                        placeholder="Industry"
                                                        className="form-control"
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.city}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>



                                        <Button className='mt-3' color="primary" type="submit">{isEditing ? 'Update Portfolio' : 'Add Portfolio'}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Existing Portfolios</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" >ID</th>
                                            <th scope="col" > Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {portfolios?.map((portfolio) => (<tr key={portfolio?.id}>
                                            <td>
                                                <p className="mb-1 font-size-12">{portfolio?.id}</p>
                                            </td>
                                            <td>
                                                <h5 className="font-size-15 mb-0">{portfolio?.title}</h5>
                                            </td>

                                            <td>
                                                <button type="button" onClick={() => handleEdit(portfolio)} className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" onClick={() => handleDeleteClick(portfolio)}
                                                    data-toggle="modal" data-target="#myModal" className="btn btn-outline-danger btn-sm me-1">Delete</button>

                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <tr>
                <td> */}

            {/* </td>
            </tr> */}
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        Delete Portfolio
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h5>
                        This Portfolio <b>{selected?.id}</b> will be deleted permanently
                    </h5>
                    {/* <p></p> */}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(selected?.id)}
                    >
                        Delete
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Portfolio;